import React from "react"

import "../../styles/index.scss"
import "../../styles/secondary_pages.scss"

import Layout from "../../components/layout"
import SEO from "../../components/seo"


import { Link, graphql } from "gatsby"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'


import BannerMobile from "../../images/editorial/practice-mgmt-banner-mobile.jpg"
import Banner from "../../images/editorial/practice-mgmt-banner.jpg"

import IconCheck from "../../images/svg/check-mark.svg"
import IconPracticeMgmt from "../../images/svg/icon-practice-mgmt-white.svg"

import ImgPracticeMgmt from "../../images/svg/practice-mgmt.svg"
import ImgGrowthCompass from "../../images/svg/growth-compass.svg"
import ImgStreamlinedSolutions from "../../images/svg/streamlined-solutions.svg"
import ImgBuildingBlocks from "../../images/svg/building-blocks.svg"
import ImgSuitcase from "../../images/svg/doctor-suitcase.svg"

const RevCycle = () => (

 <React.Fragment>
  <SEO title="Practice Management" description="Practice management made simple. For more than 35 years, MSM has been partnering with practices to deliver turnkey practice management solutions." />

  <Layout styleClass="practice-mgmt">

   <section id="sct-uvp" className="container ctr-headline secondary-headline">
    <div className="frame-image">
     <article>

      <h2>Practice management<br />
       <span>made simple.</span></h2>
      <p>Worry-free practice management.</p>

      <button className="btn-primary">
       <a href="#learnmore">Learn More</a>
      </button>
     </article>

     <picture>
      <source srcSet={BannerMobile} media="(max-width:600px)" />
      <source srcSet={Banner} />
      <img src={Banner} alt="" className="lazyload" />
     </picture>


    </div>
   </section>

   <section className="container ctr-potential ctr-newframework" id="sct-newframework" style={{ 'background': '#4d4e56' }}>
    <div className="animated ">
     <h2>Managed services for the modern medical practice.</h2>
     <p>Some days, it can feel like the demands of paperwork, technology, and other daily operations are overshadowing the main reason you’re in practice: to care for patients. But you shouldn’t have to choose between providing healthcare and growing your enterprise. MSM specializes in lending seasoned business expertise to small- and medium-sized practices — so you never need to choose where your priorities lie. </p>

     <p>Our team absorbs the bulk of your admin and back-end logistics to streamline your day-to-day, ensure your bottom line goals are met, and hatch new growth avenues for your practice. Our solution is turnkey — a robust and powerful blend of people, processes, and technology.</p>

     <p>In addition to preserving your autonomy, our practical, no-nonsense approach minimizes operating costs, maximizes efficiency, and boosts crucial patient satisfaction. We relieve you with extensive, knowledgeable support that ranges from accounting to HR, contracting to compliance, and much more.</p>



    </div>


    <img src={ImgPracticeMgmt} />

   </section>
   <a name="learnmore"></a>
   <section className="container list-container with-header" style={{ backgroundColor: "#fff" }}>
    <div className="header">
     <div className="svg">
      <img src={IconPracticeMgmt} />
     </div>
     <h5>The MSM Practice Management Tool</h5>
    </div>
    <h3>Your prescription for hassle-free practice management:</h3>


    <ul className="ctr-list-component">
     <li><span className="icon svg"><img src={IconCheck} /></span>Optimize EMR workflow.</li>
     <li><span className="icon svg"><img src={IconCheck} /></span>Put revenue back in your revenue cycle.</li>
     <li><span className="icon svg"><img src={IconCheck} /></span>Stay on top of compliance and regulatory oversight.</li>
     <li><span className="icon svg"><img src={IconCheck} /></span>Ensure your contracts are optimal.</li>
     <li><span className="icon svg"><img src={IconCheck} /></span>Hire the savviest support staff.</li>

     <li><span className="icon svg"><img src={IconCheck} /></span>Clean up maintenance and service agreements.</li>
     <li><span className="icon svg"><img src={IconCheck} /></span>Keep up with bookkeeping and accounting. </li>
     <li><span className="icon svg"><img src={IconCheck} /></span>Develop your marketing and business.</li>
     <li><span className="icon svg"><img src={IconCheck} /></span>Put medical, pharmaceutical, and office supplies on autopilot. </li>

    </ul>
   </section>

   <section className="container ctr-cta" style={{ backgroundColor: "#16cac3", color: "#fff" }}>
    <h3>Don’t merely get by. </h3>
    <p>Get ahead—by teaming up with an experienced practice management partner who understands how to secure a competitive edge. Put patient care at the forefront of your attention by exploring our options today.</p>
    <button className="btn-primary btn-inverse" style={{ backgroundColor: "#fff", color: "#16cac3" }}>
     <Link to="/contact">Learn More</Link>
    </button>
   </section>

  </Layout>




 </React.Fragment >

)

export default RevCycle;